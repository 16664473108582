<template>
  <div class="input-box-container">
    <v-row>
      <v-col
        cols="12"
        md="6"
        lg="3"
      >
        <v-text-field
          solo
          prepend-icon="A:"
          class="input-box"
          v-model.number="shapeInputs.a"
          type="number"
          v-if="shapeInputRules[selectedShape].includes('a')"
        >
          <v-icon
            slot="append"
          >
            m
          </v-icon>
        </v-text-field>
      </v-col>
      
      <v-col
        cols="12"
        md="6"
        lg="3"
      >
      <v-text-field
        solo
        prepend-icon="B:"
        class="input-box"
        v-model.number="shapeInputs.b"
        type="number"
        v-if="shapeInputRules[selectedShape].includes('b')"
      >
        <v-icon
          slot="append"
        >
          m
        </v-icon>
      </v-text-field>
      </v-col>

      <v-col
        cols="12"
        md="6"
        lg="3"
      >
      <v-text-field
        solo
        prepend-icon="C:"
        class="input-box"
        v-model.number="shapeInputs.c"
        type="number"
        v-if="shapeInputRules[selectedShape].includes('c')"
      >
        <v-icon
          slot="append"
        >
          m
        </v-icon>
      </v-text-field>
      </v-col>

      <v-col
        cols="12"
        md="6"
        lg="3"
      >
      <v-text-field
        solo
        prepend-icon="D:"
        class="input-box"
        v-model.number="shapeInputs.d"
        type="number"
        v-if="shapeInputRules[selectedShape].includes('d')"
      >
        <v-icon
          slot="append"
        >
          m
        </v-icon>
      </v-text-field>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'BasicDataShapeInputFields',
  
  data () {
    return {
      shapeInputs: {
        a: 0,
        b: 0,
        c: 0,
        d: 0,
      },
      shapeInputRules: this.$store.getters['basicData/getShapeInputRules']
    }
  },
  computed: {
    selectedShape: {
      get() {
        return this.$store.getters['basicData/getShape'];
      }
    },

  },

  watch: {
    shapeInputs: {
      // This will let Vue know to look inside the array
      deep: true,

      // Update whole object in vuex
      handler: function(newValue) {
        this.$store.commit('basicData/setShapeInputs', newValue)
      },
    }
  },
}
</script>
