<template>
  <div>
    <v-row>
      <basic-block
        v-for="item in primaryItems"
        :key="item.id"
        :item="item"
      ></basic-block>
    </v-row>
    <div class="spacer"></div>
    <v-row>
      <v-col
        cols="12"
      >
        <div class="color-picker-container">
          <div
            v-for="variation in selectedItem.variations"
            :key="variation.id"
            :class="['color', variation.color, (selectedVariation.color == variation.color ? 'selected' : '')]" @click="updateVariation(variation)"
          >
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
      >
        <div class="instrcutions">
          <h3 class="instrcutions-title">
            {{ $t(`basicData.sizeAndColorPicker.instructions.title`) }}
          </h3>
          <h5 class="instructions-context">
            {{ $t(`basicData.sizeAndColorPicker.instructions.subtitle`) }}
          </h5>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import BasicBlock from './BasicBlock.vue'

export default {
  name: 'BasicDataSizeAndColorPicker',

  components:  {
    BasicBlock
  },

  data () {
    return {
      //selectedColor: 'blue',
      //selectedSize: 'double'
    }
  },

  computed: {
    primaryItems: {
      get() {
        return this.$store.getters['api/getPrimaryItems'];
      },
    },
    selectedItem: {
      get() {
        let storedItem = this.$store.getters['configurator/getPrimaryItem']

        if (storedItem) return storedItem

        return this.primaryItems[0]
      },
    },
    selectedVariation: {
      get() {
        let storedVariation = this.$store.getters['configurator/getPrimaryItemVariation']
        
        if (storedVariation) {
          //return stored variation
          return storedVariation

        } else {
          //preselect first variation and set it in store
          this.$store.commit('configurator/setPrimaryItemVariation', this.item)
          return this.selectedItem.variations[0]
        }
      },
      set(newValue) {
        this.$store.commit('configurator/setPrimaryItemVariation', newValue)
      }
    },
  },

  methods: {
    updateVariation(variation) {
      this.$store.commit('basicData/setColor', variation.color)
      this.selectedVariation = variation
    }
  }
}
</script>
