<template>
  <v-col 
    cols="6"
    :class="['basic-block', item.rule, (selectedItem.name == item.name ? 'selected' : ''), selectedVariation.color]"
    @click="selectedItem = item"
  >
    <div class="size-title">
      {{ $t(`basicData.basicBlock.${item.rule}`) }}
    </div>
    <div class="size-image">
    </div>
  </v-col>
</template>

<script>


export default {
  name: 'BasicBlock',

  props: {
    item: {
      type: Object,
      reqired: true,
    }
  },

  data () {
    return {
    }
  },
  computed: {
    primaryItems: {
      get() {
        return this.$store.getters['api/getPrimaryItems'];
      },
    },
    selectedItem: {
      get() {
        let storedItem = this.$store.getters['configurator/getPrimaryItem']

        if (storedItem) {
          //return stored item
          return storedItem

        } else {
          //preselect first item and set it in store
          this.$store.commit('configurator/setPrimaryItem', this.item)
          return this.primaryItems[0]
        }
        

        //return this.primaryItems[0]
      },
      set() {
        this.$store.commit('basicData/setBasicBlock', this.item.name)
        this.$store.commit('configurator/setPrimaryItem', this.item) //store local item to store
      }
    },
    selectedVariation: {
      get() {
        let storedVariation = this.$store.getters['configurator/getPrimaryItemVariation']
        
        if (storedVariation) return storedVariation

        //no variation is stored so selected the first one
        return this.item.variations[0]
      },
      set(newValue) {
        this.$store.commit('configurator/setPrimaryItemVariation', newValue)
      }
    },
  },
}
</script>
