<template>
  <div class="shape-form-container">
    <v-row>
      <v-col 
        cols="12"
      >
        <basic-data-shape-input-fields></basic-data-shape-input-fields>
      </v-col>
      <v-col
        cols="12"
      >
        <div :class="['shape-image-container', selectedShape, selectedVariation.color]">
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
      >
        <div class="shape-picker-container">
          <div :class="['shape rectangle',  (selectedShape == 'rectangle' ? 'selected' : ''), selectedVariation.color]" @click="selectedShape = 'rectangle'"></div>
          <div :class="['shape tform',  (selectedShape == 'tform' ? 'selected' : ''), selectedVariation.color]" @click="selectedShape = 'tform'"></div>
          <div :class="['shape square',  (selectedShape == 'square' ? 'selected' : ''), selectedVariation.color]" @click="selectedShape = 'square'"></div>
          <div :class="['shape uform',  (selectedShape == 'uform' ? 'selected' : ''), selectedVariation.color]" @click="selectedShape = 'uform'"></div>
          <div :class="['shape empty',  (selectedShape == 'empty' ? 'selected' : ''), selectedVariation.color]" @click="selectedShape = 'empty'"></div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import BasicDataShapeInputFields from './BasicDataShapeInputFields.vue'

export default {
  name: 'BasicDataShapePicker',

  components: {
    BasicDataShapeInputFields
  },

  data () {
    return {
      //selectedShape: "rectangle",
    }
  },

  computed: {
    selectedShape: {
      get() {
        return this.$store.getters['basicData/getShape']
      },
      set(newValue) {
        this.$store.commit('basicData/setShape', newValue)
      }
    },
    primaryItems: {
      get() {
        return this.$store.getters['api/getPrimaryItems'];
      },
    },
    selectedItem: {
      get() {
        let storedItem = this.$store.getters['configurator/getPrimaryItem']

        if (storedItem) return storedItem

        return this.primaryItems[0]
      },
    },
    selectedVariation: {
      get() {
        let storedVariation = this.$store.getters['configurator/getPrimaryItemVariation']
        
        if (storedVariation) {
          //return stored variation
          return storedVariation

        } else {
          //preselect first variation and set it in store
          this.$store.commit('configurator/setPrimaryItemVariation', this.item)
          return this.selectedItem.variations[0]
        }
      },
      set(newValue) {
        this.$store.commit('configurator/setPrimaryItemVariation', newValue)
      }
    },
  },
}
</script>
